


























































































import { Component, Vue, Prop } from "vue-property-decorator";
import MainHeader from "../../../main/components/main-header/main-header.vue";
import NavLeft from "../../../main/components/nav-left/nav-left.vue";
// import TemplateTransform from "../../components/template-transform/template-transform.vue";
import { getCepingDetail } from "@/request/doctor";
@Component({
  components: {
    MainHeader,
    NavLeft,
    // TemplateTransform,
  },
})
export default class Name extends Vue {
  private ifCompose: boolean = false;
  private H: any;
  private data: any = {};
  private get patient() {
    return this.$store.state.patient;
  }
  private getData() {
    const params = {
      content_id: this.data.content_id,
    };
    getCepingDetail(this, params).then((res: any) => {
      this.data = res;
    });
  }
  mounted() {
    this.H = document.body.clientHeight - 160;
    if (this.$route.query.content_id) {
      this.data.content_id = this.$route.query.content_id;
      this.getData();
    }
  }
}
